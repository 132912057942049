@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overscroll-behavior-x: contain;
  height: 100vh;
  width: 100vw;
}

body, #root {
  height: 100%;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.react-date-picker__calendar--open, .react-date-picker__calendar--closed {
  position: absolute
}


.Mui-TableHeadCell-Content-Actions.MuiBox-root, .Mui-TableHeadCell-ResizeHandle-Wrapper.MuiBox-root {
  margin-top: auto;
  margin-bottom: auto;
}

.Mui-TableHeadCell-Content-Wrapper, .MuiBox-root {
  white-space: nowrap !important;
}

.MuiFormControl-root, .MuiFormControl-fullWidth {
  margin-top: 4px !important;
}

/* #root > div > div > div.md\:overflow-x-hidden.w-full > div > section > div > div.MuiTableContainer-root.css-15ucj8o-MuiTableContainer-root.e1de0imv0 > table > thead > tr > th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-miarqp-MuiTableCell-root.e1de0imv0 > div > div.Mui-TableHeadCell-Content-Actions.MuiBox-root.css-r7bkot-stylesFactory.e1de0imv0 */